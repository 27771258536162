<template>
  <v-app-bar app class="mh-blue-blur pr-6">

    <!-- Facebook ikona vlevo na mobilní verzi -->
    <v-btn aria-label="facebook" variant="text" class="d-md-none" @click="openFacebookProfile">
      <img alt="facebook" height="35" width="35" src="@/assets/icons/facebook.png" />
    </v-btn>
    <v-btn aria-label="instagram" variant="text" class="d-md-none" @click="openInstagramProfile">
      <img alt="instagram" height="35" width="35" src="@/assets/icons/instagram.png" />
    </v-btn>
    <v-btn aria-label="youtube" variant="text" class="d-md-none" @click="openYoutubeProfile">
      <img alt="youtube" height="35" width="35" src="@/assets/icons/youtube.png" />
    </v-btn>

    <v-btn aria-label="facebook" variant="text" icon @click="openFacebookProfile" class="d-none d-md-flex ml-12"><img
        alt="facebook" height="35" width="35" src="@/assets/icons/facebook.png" /></v-btn>
    <v-btn aria-label="instagram" variant="text" icon @click="openInstagramProfile" class="d-none d-md-flex"><img
        alt="instagram" height="35" width="35" src="@/assets/icons/instagram.png" /></v-btn>
    <v-btn aria-label="youtube" icon variant="text" @click="openYoutubeProfile" class="d-none d-md-flex">
      <img alt="youtube" height="35" width="35" src="@/assets/icons/youtube.png" />
    </v-btn>
    <v-spacer></v-spacer>
    <!-- Menu pro desktop -->
    <v-btn @click="router.push('/')" variant="text" class="d-none d-md-flex">Domů</v-btn>
    <v-btn v-for="item in menuTitles" :key="item._id" @click="navigateToArticle(item.urlTitle)" variant="text" class="d-none d-md-flex">
      {{ item.menuTitle }}
    </v-btn>
    <v-btn @click="router.push('/leagues')" variant="text" class="d-none d-md-flex">Soutěže</v-btn>
    <v-btn @click="router.push('/articles')" variant="text" class="d-none d-md-flex">Články</v-btn>
    
    <v-btn @click="router.push('/contact')" variant="text" class="d-none d-md-flex">Kontakt</v-btn>

    <!-- Zobrazit avatar s rozbalovací nabídkou, pokud je uživatel přihlášen -->
    <template v-if="userStore.isAuthenticated">
      <v-menu class="user-menu" offset="4" width="200px" aria-label="Profilová nabídka">
        <template #activator="{ props }">
          <v-btn icon>
            <v-avatar v-bind="props" class="d-none d-md-flex cursor-pointer" color="primary" size="36">
              <span>{{ userInitials }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-list :style="{ backgroundColor: $vuetify.theme.themes.customDarkTheme.colors.listBackground }">
          <v-list-item v-if="userStore.isAuthenticated" @click="openMyProfile">
            <v-list-item-icon><v-icon>mdi-badge-account-outline</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Můj profil</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="canSeeUsers" @click="router.push('/users/userList')">
            <v-list-item-icon><v-icon>mdi-account-group</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Hráči</v-list-item-title>
            </v-list-item-content>
          </v-list-item>


          <v-list-item v-if="canSeeUsers" @click="router.push('/newarticle')">
            <v-list-item-icon><v-icon>mdi-text-box-plus-outline</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Přidat obsah</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="canSeeUsers" @click="router.push('/newleague')">
            <v-list-item-icon><v-icon>mdi-table-plus</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Přidat soutěž</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="canSeeUsers" @click="router.push('/newteam')">
            <v-list-item-icon><v-icon>mdi-account-multiple-plus</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Přidat tým</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="userStore.isAuthenticated" @click="logout">
            <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Odhlásit se</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-else @click="appStore.showLoginOverlay()">
            <v-list-item-icon><v-icon>mdi-login</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Login</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

      </v-menu>
    </template>

    <!-- Ikonka menu pro mobilní zařízení -->
    <v-app-bar-nav-icon @click="drawer = !drawer" class="d-md-none"></v-app-bar-nav-icon>
  </v-app-bar>

  <!-- Boční menu pro mobilní zařízení -->
  <v-navigation-drawer v-model="drawer" app temporary color="mhblue" location="end">
    <v-list>

      <v-list-item v-if="userStore.isAuthenticated" @click="openMyProfile">
        <v-list-item-title><v-icon>mdi-badge-account-outline</v-icon> Můj profil</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="canSeeUsers" @click="router.push('/users/userList')">
        <v-list-item-title><v-icon>mdi-account-group</v-icon> Hráči</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="canSeeUsers" @click="router.push('/newarticle')">
        <v-list-item-title><v-icon>mdi-text-box-plus-outline</v-icon> Přidat obsah</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="canSeeUsers" @click="router.push('/newleague')">
        <v-list-item-title><v-icon>mdi-table-plus</v-icon> Přidat ligu</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="canSeeUsers" @click="router.push('/newarticle')">
        <v-list-item-title><v-icon>mdi-account-multiple-plus</v-icon> Přidat tým</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="userStore.isAuthenticated" @click="logout">
        <v-list-item-title><v-icon>mdi-logout</v-icon> Odhlásit</v-list-item-title>
      </v-list-item>
      <v-list-item v-else @click="appStore.showLoginOverlay()">
        <v-list-item-title><v-icon>mdi-login</v-icon> Login</v-list-item-title>
      </v-list-item>
      <v-divider></v-divider>

      <v-list-item @click="router.push('/')">
        <v-list-item-title>Domů</v-list-item-title>
      </v-list-item>
      
      <v-list-item v-for="item in menuTitles" :key="item._id" @click="navigateToArticle(item.urlTitle)" >
        <v-list-item-title>{{ item.menuTitle }}</v-list-item-title>
      </v-list-item>
      <v-list-item @click="router.push('/leagues')">
        <v-list-item-title>Soutěže</v-list-item-title>
      </v-list-item>
      <v-list-item @click="router.push('/articles')">
        <v-list-item-title>Články</v-list-item-title>
      </v-list-item>
      <v-list-item @click="router.push('/contact')">
        <v-list-item-title>Kontakt</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>

</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import { useUserStore } from '@/stores/user/userStore'; // Import user store
import { useAppStore } from '@/stores/app/appStore'; // Import app store
import { useRouter } from 'vue-router';
import axiosInstance from '@/services/axiosInstance';


// Přístup k routeru
const router = useRouter();

// Stav pro boční menu (drawer)
const drawer = ref(false);

const menuTitles = ref<{ _id: string; menuTitle: string; urlTitle: string }[]>([]);
// Přístup do user store
const userStore = useUserStore();
const appStore = useAppStore();

// Kontrola, zda přihlášený uživatel má roli coach nebo administrator
const canSeeUsers = computed(() => {
  const roles = userStore.user?.userRoles || []
  return roles.includes('administrator')
})


const openMyProfile = () => {
  // console.log('Otevřít můj profil');

  // Zajištění, že `userStore.user` existuje a obsahuje požadované hodnoty
  if (!userStore.user || !userStore.user._id || !userStore.user.searchName) {
    console.error('Uživatelská data nejsou kompletní');
    return;
  }

  const searchNameArr = userStore.user.searchName.split(' ');

  // Zajištění, že `userStore.user` existuje a obsahuje požadované hodnoty
  if (searchNameArr.length < 2) {
    console.error('searchNameArr data nejsou kompletní');
    return;
  }
  // console.log('searchNameArr:', searchNameArr);

  const myProfileRoute = `/users/${userStore.user._id}-${searchNameArr[0]}-${searchNameArr[1]}`;

  // console.log('Otevřít můj profil:', myProfileRoute);

  // Odeslání uživatele na stránku profilu
  router.push(myProfileRoute);
};

const navigateToArticle = (urlTitle: string) => {
  router.push(`/articles/${urlTitle}`);
};

const openFacebookProfile = () => {
  // console.log('Otevřít Facebook profil');
  window.open('https://www.facebook.com/www.modernhockey.eu', '_blank');
};

const openInstagramProfile = () => {
  // console.log('Otevřít Facebook profil');
  window.open('https://www.instagram.com/modernhockey.eu/', '_blank');
};

const openYoutubeProfile = () => {
  // console.log('Otevřít Facebook profil');
  window.open('https://www.youtube.com/@Modernhockeyeu', '_blank');
};


// Výpočet iniciál uživatele
const userInitials = computed(() => {
  if (!userStore.user) return '';
  const names = userStore.user.name.split(' ');
  return names.map(n => n[0]).join('').toUpperCase();
});

// Funkce pro odhlášení
const logout = () => {
  userStore.clearUser(); // Vymazat uživatelská data ze store a odhlásit uživatele
};



onMounted(async () => {
  try {
    const response = await axiosInstance.get(`/articles/menutitles`);
    menuTitles.value = response.data; // Načtení položek do `menuTitles`
  } catch (error) {
    console.error('Chyba při načítání menu položek:', error);
  }
});



</script>

<style scoped>
.fb-icon {
  border-radius: 25px;
}

.user-menu {
  -webkit-backdrop-filter: blur(4px);
  /* Pro Safari */
  backdrop-filter: blur(4px);
}

.v-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: white;
}

/* Nastavení maximální šířky pro mobilní zařízení */
@media (max-width: 600px) {
  .v-menu__content {
    max-width: 150px !important;
    /* Menu bude užší na menších zařízeních */
  }
}

/* Nastavení větší šířky pro větší zařízení */
@media (min-width: 600px) {
  .v-menu__content {
    max-width: 200px !important;
    /* Menu bude širší na větších zařízeních */
  }
}
</style>